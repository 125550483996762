import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthGuard } from "../App";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddMaintenanceRequest = () => {
  const [requestContent, setRequestContent] = useState("");
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const userId = userData.unique;
  const propertyId = localStorage.getItem("specificPropertyId");

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `https://backend.inyumba.co.ke/iNyumba/maintenance/properties/${propertyId}/users/${userId}/maintenance-requests`,
        {
          request_content: requestContent,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      toast.success(response.data.response);

      navigate("/user/home");
    } catch (error) {
      console.error("Error adding maintenance request:", error);
      toast.error(error.response.data.response);
      // Handle error
    }
  };

  const handleCancel = () => {
    navigate("/user/home");
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Add Maintenance Request</h1>
        <form className="w-full max-w-lg">
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="request-content"
              >
                Write what you want fixed
              </label>
              <textarea
                id="request-content"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                rows="5"
                value={requestContent}
                onChange={(e) => setRequestContent(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={handleSubmit}
            >
              Add Maintenance Request
            </button>
          </div>
        </form>
      </div>
    </AuthGuard>
  );
};

export default AddMaintenanceRequest;
