import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthGuard } from "../App";
import { useNavigate } from "react-router-dom";

const SpecificPropertyPage = () => {
  const [propertyInfo, setPropertyInfo] = useState({});
  const [amenities, setAmenities] = useState([]);
  const [rentals, setRentals] = useState([]);
  const [propertyRatings, setPropertyRatings] = useState(null); // State to hold property ratings
  const token = localStorage.getItem("token");
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const userId = userData.unique;
  const navigate = useNavigate();
  const propertyId = localStorage.getItem("specificPropertyId");

  useEffect(() => {
    // Fetch property information
    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/admin/properties/records/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setPropertyInfo(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching property information:", error);
      });

    // Fetch amenities
    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/amenities/fetch/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setAmenities(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching amenities:", error);
      });

    // Fetch rentals
    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/admin/rentals/property/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setRentals(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching rentals:", error);
      });

    // Fetch property ratings
    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/admin/comments/average-ratings/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setPropertyRatings(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching property ratings:", error);
      });
  }, [propertyId, token]);

  const handleRentalClick = (RentalId) => {
    localStorage.setItem("specificRentalId", RentalId);
    navigate("/user/specificrental");
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        {/* Property Information */}
        <h1 className="text-3xl font-semibold mb-4">{propertyInfo.title}</h1>
        <img
          src={propertyInfo.photo}
          alt={propertyInfo.title}
          className="w-full h-32 object-cover mb-2 rounded"
        />
        <div className="text-gray-600 mb-1">
          <strong className="font-semibold">Description:</strong>{" "}
          {propertyInfo.description}
        </div>
        <div className="text-gray-600 mb-1">
          <strong className="font-semibold">County:</strong>{" "}
          {propertyInfo.county}
        </div>
        <div className="text-gray-600 mb-1">
          <strong className="font-semibold">Location:</strong>{" "}
          {propertyInfo.location}
        </div>

        {/* Property Ratings */}
        <div className="text-gray-600 mb-1">
          <strong className="font-semibold">Average Rating:</strong>{" "}
          {propertyRatings || 0}/5
        </div>

        {/* Rentals */}
        <div className="mt-8">
          <h2 className="text-xl font-semibold mb-2">Rentals</h2>
          <div className="flex flex-nowrap overflow-x-auto">
            {rentals.map((rental) => (
              <div
                key={rental.rental_id}
                className="flex-shrink-0 flex flex-col bg-white shadow-md rounded-md p-6 m-4 cursor-pointer"
                onClick={() => handleRentalClick(rental.rental_id)}
              >
                <img
                  src={rental.photo}
                  alt={rental.property_name}
                  className="w-full h-32 object-cover mb-2 rounded"
                />
                <div className="text-base font-semibold mb-1">
                  {rental.property_name}
                </div>
                <div className="text-gray-600 mb-1">{rental.description}</div>
                <div className="text-gray-600 mb-1">
                  House Type: {rental.house_type}
                </div>
                <div className="text-gray-600 mb-1">
                  Rent Price: {rental.rent_price}
                </div>
                <div className="text-gray-600 mb-1">
                  <p>Location: {propertyInfo.location}</p>
                </div>
                <div className="text-gray-600 mb-1">
                  <p>Description: {propertyInfo.description}</p>
                </div>
              </div>
            ))}
          </div>
          <h2 className="text-xl font-semibold mb-2">Amenities</h2>
          <div className="flex flex-wrap">
            {amenities.map((amenity, index) => (
              <div key={index} className="bg-gray-100 rounded-md p-2 m-2">
                <p className="text-gray-800">{amenity.amenity_name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </AuthGuard>
  );
};

export default SpecificPropertyPage;
