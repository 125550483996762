import React, { useState, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";
import { useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";

const AgencyViewingFee = () => {
  const [webUrl, setWebUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showIframe, setShowIframe] = useState(false);
  const [showPaidMessage, setShowPaidMessage] = useState(false);
  const token = localStorage.getItem("token");
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const userId = userData.unique;
  const iframeRef = useRef(null);
  const navigate = useNavigate();

  const handleCheckAgencyFee = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const userDataString = localStorage.getItem("user");
    const userData = JSON.parse(userDataString);
    const userId = userData.unique;

    const response = await axios.post(
      `https://backend.inyumba.co.ke/iNyumba/user/agencyviewingfee/check-payment-status/${userId}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (
      response.data.success === true &&
      response.data.response === "You have already paid. No need to pay again."
    ) {
      toast.error(response.data.response);
      setIsLoading(false);
    } else {
      setWebUrl(response.data.response);
      toast.success("Please Complete Payment");
      setWebUrl(response.data.response);
      setShowIframe(true);
      setIsLoading(false);
    }
  };

  const handleCloseIframe = () => {
    setShowIframe(false); // Hide the iframe
    setWebUrl(""); // Clear the payment URL
    navigate("/user/home");
  };

  return (
    <AuthGuard>
      <div className="flex justify-center items-center min-h-screen">
        <button
          onClick={handleCheckAgencyFee}
          className={`bg-blue-500 text-white px-4 py-2 rounded ${
            isLoading ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-600"
          }`}
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "Check Agency Fee"}
        </button>

        {/* Conditionally render the iframe with close button */}
        {showIframe && webUrl && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex flex-col items-center justify-center">
            <button
              onClick={handleCloseIframe}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mb-4"
            >
              Close
            </button>
            <iframe
              src={webUrl}
              className="w-screen h-screen border-none mx-auto"
              title="Payment Iframe"
            ></iframe>
          </div>
        )}
      </div>
    </AuthGuard>
  );
};

export default AgencyViewingFee;
