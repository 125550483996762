import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthGuard } from "../App";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const VacatePage = () => {
  const navigate = useNavigate();

  const handleVacate = () => {
    const token = localStorage.getItem("token");
    const userDataString = localStorage.getItem("user");
    const userData = JSON.parse(userDataString);
    const userId = userData.unique;

    axios
      .post(
        `https://backend.inyumba.co.ke/iNyumba/vacatinghouse/users/${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.response);

        // Redirect to reservations page after vacating
        navigate("/user/home");
      })
      .catch((error) => {
        console.error("Error vacating house:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleCancel = () => {
    // Navigate back to reservations page
    navigate("/user/home");
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-6">Vacate Confirmation</h1>
        <p className="text-lg mb-6">Are you sure you want to vacate?</p>
        <div className="flex justify-center">
          <button
            onClick={handleVacate}
            className="btn bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-6 rounded-full mr-4 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
          >
            Yes
          </button>
          <button
            onClick={handleCancel}
            className="btn bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-6 rounded-full transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
          >
            No
          </button>
        </div>
      </div>
    </AuthGuard>
  );
};

export default VacatePage;
