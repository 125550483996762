import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthGuard } from "../App";

const ReservationsPage = () => {
  const [reservations, setReservations] = useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const userId = userData.unique;

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      // Fetch reservations
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/user/reservations/viewYour/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setReservations(response.data.response);
        })
        .catch((error) => {
          console.error("Error fetching reservations:", error);
        });
    }
  }, []);

  const handleCommentClick = (propertyId) => {
    localStorage.setItem("specificPropertyId", propertyId);
    navigate("/user/addcomment");
  };

  const handleMaintenanceClick = (propertyId) => {
    localStorage.setItem("specificPropertyId", propertyId);
    navigate("/user/addmaintenance");
  };

  const handleVacateClick = () => {
    navigate("/user/addvacate");
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Reservations</h1>
        <span className="text-1xl font-semibold mb-8">
          Date: {new Date().toLocaleDateString()}
        </span>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {reservations.map((reservation) => (
            <div
              key={reservation.room_id}
              className="bg-white shadow-md rounded-md p-4 border border-gray-300 mb-4 hover:shadow-lg"
            >
              <p>
                <strong>User Name:</strong> {reservation.user_name}
              </p>
              <p>
                <strong>Email:</strong> {reservation.email}
              </p>
              <p>
                <strong>Phone:</strong> {reservation.phone}
              </p>
              <p>
                <strong>Entry Date:</strong> {reservation.entrydate}
              </p>
              <p>
                <strong>House Number:</strong> {reservation.house_no}
              </p>
              <p>
                <strong>Booking Status:</strong> {reservation.booking_status}
              </p>
              <p>
                <strong>Deposit Paid:</strong> {reservation.deposit_paid}
              </p>
              <p>
                <strong>Payment Status:</strong> {reservation.payment_status}
              </p>
              {/* Comment Button */}
              <button
                onClick={() => handleCommentClick(reservation.property_id)}
                className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
              >
                Comment
              </button>

              {/* Maintenance Button */}
              <button
                onClick={() => handleMaintenanceClick(reservation.property_id)}
                className="btn bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded mr-2"
              >
                Maintenance
              </button>

              {/* Vacate Button */}
              <button
                onClick={handleVacateClick}
                className="btn bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              >
                Vacate
              </button>
            </div>
          ))}
        </div>
      </div>
    </AuthGuard>
  );
};

export default ReservationsPage;
