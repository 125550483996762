import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthGuard } from "../App";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Oval } from "react-loader-spinner"; // Import loader

const Settlement = () => {
  const [settlementData, setSettlementData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSettlementData = async () => {
      setLoading(true);

      const token = localStorage.getItem("token");
      const userDataString = localStorage.getItem("user");
      const userData = JSON.parse(userDataString);
      const userId = userData.unique;

      try {
        const response = await axios.get(
          `https://backend.inyumba.co.ke/iNyumba/admin/settlementcheck/userindividual/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setSettlementData(response.data.response); // Adjust based on your response structure
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchSettlementData();
  }, []);

  // Render loading state while fetching data
  if (loading) {
    return <div className="container mx-auto px-4 py-8">Loading...</div>;
  }

  // Render the settlements data in a table
  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Settlements</h1>
        <div className="bg-white shadow-md rounded-md p-4">
          <table className="min-w-full">
            <thead>
              <tr className="bg-gray-200">
                <th className="py-2 px-4 border-b">Settlement ID</th>
                <th className="py-2 px-4 border-b">Settlement Amount</th>
                <th className="py-2 px-4 border-b">Transaction Cost</th>
                <th className="py-2 px-4 border-b">Inyumba Reference</th>
                <th className="py-2 px-4 border-b">Payment Reference</th>
                <th className="py-2 px-4 border-b">Status</th>
                <th className="py-2 px-4 border-b">Date Settled</th>
              </tr>
            </thead>
            <tbody>
              {settlementData.length > 0 ? (
                settlementData.map((settlement, index) => (
                  <tr key={index} className="hover:bg-gray-100">
                    <td className="py-2 px-4 border-b">
                      {settlement.settlement_id}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {settlement.total_amount_paid}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {settlement.transactioncost}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {settlement.reference}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {settlement.paymentreference}
                    </td>
                    <td className="py-2 px-4 border-b">{settlement.status}</td>
                    <td className="py-2 px-4 border-b">
                      {new Date(settlement.created_at).toLocaleDateString()}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="py-2 px-4 text-center">
                    No settlements found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </AuthGuard>
  );
};

export default Settlement;
