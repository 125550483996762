import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthGuard } from "../App";

const WaterBillPage = () => {
  const [waterBill, setWaterBill] = useState(null);
  const token = localStorage.getItem("token");
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const userId = userData.unique;

  useEffect(() => {
    const fetchWaterBill = async () => {
      try {
        const response = await axios.get(
          `https://backend.inyumba.co.ke/iNyumba/admin/waterbill/getspecific/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setWaterBill(response.data.response);
      } catch (error) {
        console.error("Error fetching water bill:", error);
      }
    };

    if (token) {
      fetchWaterBill();
    }
  }, [token, userId]);

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Water Bill</h1>
        <span className="text-1xl font-semibold mb-8">
          Date: {new Date().toLocaleDateString()}
        </span>

        {waterBill ? (
          <div className="container mx-auto px-4 py-8">
            <div className="bg-white shadow-md rounded-md p-6">
              <p className="text-lg font-semibold mb-4">Water Bill Details</p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <p className="text-gray-700">
                    <strong>House Number:</strong> {waterBill.house_number}
                  </p>
                  <p className="text-gray-700">
                    <strong>Previous Units:</strong> {waterBill.previousunits}
                  </p>
                </div>
                <div>
                  <p className="text-gray-700">
                    <strong>Current Units:</strong> {waterBill.currentunits}
                  </p>
                  <p className="text-gray-700">
                    <strong>Amount:</strong> {waterBill.amount}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p>No water bill available for this user.</p>
        )}
      </div>
    </AuthGuard>
  );
};

export default WaterBillPage;
