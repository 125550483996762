import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthGuard } from "../App";

const PreviousReservationsPage = () => {
  const [previousReservations, setPreviousReservations] = useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const userId = userData.unique;

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      // Fetch previous reservations
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/user/reservations/viewyourpreviousreservation/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setPreviousReservations(response.data.response);
        })
        .catch((error) => {
          console.error("Error fetching previous reservations:", error);
        });
    }
  }, []);

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Previous Reservations</h1>
        <span className="text-1xl font-semibold mb-8">
          Date: {new Date().toLocaleDateString()}
        </span>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {previousReservations.map((reservation) => (
            <div
              key={reservation.room_id}
              className="bg-white shadow-md rounded-md p-4"
            >
              <p>
                <strong>User Name:</strong> {reservation.user_name}
              </p>
              <p>
                <strong>Email:</strong> {reservation.email}
              </p>
              <p>
                <strong>Phone:</strong> {reservation.phone}
              </p>
              <p>
                <strong>Entry Date:</strong> {reservation.entrydate}
              </p>
              <p>
                <strong>House Number:</strong> {reservation.house_no}
              </p>
              <p>
                <strong>Booking Status:</strong> {reservation.booking_status}
              </p>
              <p>
                <strong>Deposit Paid:</strong> {reservation.deposit_paid}
              </p>
              <p>
                <strong>Payment Status:</strong> {reservation.payment_status}
              </p>
            </div>
          ))}
        </div>
      </div>
    </AuthGuard>
  );
};

export default PreviousReservationsPage;
