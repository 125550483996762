import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthGuard } from "../App";

const Profile = () => {
  const [usersData, setUsersData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        const userDataString = localStorage.getItem("user");
        const userData = JSON.parse(userDataString);
        const userId = userData.unique;
        const token = localStorage.getItem("token");

        // Fetch user data from the endpoint
        const response = await axios.get(
          `https://backend.inyumba.co.ke/iNyumba/users/${userId}/edit`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Set the user data state
        setUsersData(response.data.response);
        setLoading(false);
      } catch (error) {
        // Handle errors
        setError(error.message);
        setLoading(false);
      }
    };

    // Call the fetchUsersData function when the component mounts
    fetchUsersData();
  }, []);

  // Render loading state while fetching data
  if (loading) {
    return <div className="container mx-auto px-4 py-8">Loading...</div>;
  }

  // Render error message if there's an error
  if (error) {
    return <div className="container mx-auto px-4 py-8">Error: {error}</div>;
  }

  // Render the profile data
  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Profile</h1>
        <span className="text-1xl font-semibold mb-8">
          Date: {new Date().toLocaleDateString()}
        </span>
        <div className="bg-white shadow-md rounded-md p-4">
          <p className="mb-2">
            <strong>Name:</strong> {usersData.name}
          </p>
          <p className="mb-2">
            <strong>Email:</strong> {usersData.email}
          </p>
          <p className="mb-2">
            <strong>Phone:</strong> {usersData.phone}
          </p>
          <p className="mb-2">
            <strong>Status:</strong> {usersData.status}
          </p>
          <p className="mb-2">
            <strong>Access Role:</strong> {usersData.role}
          </p>
          <p className="mb-2">
            <strong>Date Of Joining:</strong> {usersData.created_at}
          </p>
        </div>
      </div>
    </AuthGuard>
  );
};

export default Profile;
