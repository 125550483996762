import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthGuard } from "../App";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FavoritesPage = () => {
  const [favorites, setFavorites] = useState([]);
  const [rentals, setRentals] = useState([]);
  const token = localStorage.getItem("token");
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const userId = userData.unique;
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch favorites
    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/favorite/users/${userId}/favorites`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setFavorites(response.data.response);
        // Fetch rental details for each favorite
        Promise.all(
          response.data.response.map((favorite) =>
            axios.get(
              `https://backend.inyumba.co.ke/iNyumba/admin/rentals/specific/${favorite.rental_id}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
          )
        ).then((rentalResponses) => {
          const rentalData = rentalResponses.map((res) => res.data.response);
          setRentals(rentalData);
        });
      })
      .catch((error) => {
        console.error("Error fetching favorites:", error);
        toast.error(error.response.data.response);
      });
  }, [userId, token]);

  const handleDeleteFavorite = (rentalId, event) => {
    // Prevent the event from propagating
    event.stopPropagation();
    // Find the favorite with the corresponding rental_id
    const favoriteToDelete = favorites.find(
      (favorite) => favorite.rental_id === rentalId
    );

    if (!favoriteToDelete) {
      console.error("Favorite not found for deletion");
      return;
    }

    // Delete favorite
    axios
      .delete(
        `https://backend.inyumba.co.ke/iNyumba/favorite/users/${userId}/favorites/${favoriteToDelete.rental_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        // Remove the deleted favorite from the favorites list
        setFavorites(
          favorites.filter((favorite) => favorite.rental_id !== rentalId)
        );
        toast.success("Successfully deleted favorite. Reload for changes");
      })
      .catch((error) => {
        console.error("Error deleting favorite:", error);
        toast.error("Failed to delete favorite");
      });
  };

  const handleViewRental = (rentalId) => {
    localStorage.setItem("specificRentalId", rentalId);
    navigate("/user/specificrental");
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-8">Favorites</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {rentals.map((rental, index) => (
            <div
              key={index}
              className="bg-white shadow-md rounded-md p-6 cursor-pointer"
              onClick={() => handleViewRental(rental.rental_id)}
            >
              <img
                src={rental.photo}
                alt={`Rental ${rental.rental_id}`}
                className="w-full h-auto mb-4"
              />
              <p className="text-lg font-semibold mb-2">
                House Type: {rental.house_type}
              </p>
              <p className="text-lg font-semibold mb-2">
                Price: {rental.rent_price}
              </p>
              <p className="text-lg font-semibold mb-2">
                House Number: {rental.house_number}
              </p>
              <p className="text-gray-600 mb-4">
                Description: {rental.description}
              </p>
              <p className="text-lg font-semibold mb-2">
                Availability: {rental.status}
              </p>
              <button
                onClick={(event) =>
                  handleDeleteFavorite(rental.rental_id, event)
                }
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-full mr-4"
              >
                Delete
              </button>
            </div>
          ))}
        </div>
      </div>
    </AuthGuard>
  );
};

export default FavoritesPage;
